/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";

import { useTranslation, Trans } from "react-i18next";
/* --------------------------------------------------------------*/

// import inages
import defaultUser from "assets/ActivityAssests/images/default-user.png";

//import add tag modal
import AddTagModalLeftPanel from "components/ActivityComponents/ActivityModal/AddTagModalLeftPanel";

const ActivityLeftpanel = (props) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // saving loggedin user's Id
  const loggedInUserID = localStorage.getItem("userID");

  const location = useLocation();

  let winnerIdState = location.state;

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [firstPostId, setFirstPostId] = useState(null);
  const [activityPost, setActivityPost] = useState([]);
  const [loadTopics, setLoadTopics] = useState(false);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [postId, setPostId] = useState(null);

  // the following useState is used to increment the page number and render it accordingly.
  const [page, setPage] = useState(1);

  /*--- function for get all post lists ---*/
  const allForums = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITIES_LIST +
      `?token=${token}` +
      `&page=${page}`;

    if (winnerIdState != undefined) {
      let winnerQuery = `&winner[]=${winnerIdState.id}`;
      requestURL = requestURL + `${winnerQuery}`;
    }

    if (props.searchValue != "") {
      if (props.searchValue.length > 1) {
        requestURL = requestURL + `&query=${props.searchValue}`;
      }
    }

    if (props.title != "") {
      if (props.title.length > 1) {
        requestURL = requestURL + `&query=${props.title}`;
      }
    }

    if (props.contact.length > 0) {
      let contactQuery = "";
      props.contact.map((data) => {
        contactQuery += `&contact[]=${data}`;
      });
      requestURL = requestURL + `${contactQuery}`;
    }

    if (props.winner.length > 0) {
      let winnerQuery = "";
      props.winner.map((data) => {
        winnerQuery += `&winner[]=${data}`;
      });
      requestURL = requestURL + `${winnerQuery}`;
    }

    if (props.event.length > 0) {
      let eventQuery = "";
      props.event.map((data) => {
        eventQuery += `&event[]=${data}`;
      });
      requestURL = requestURL + `${eventQuery}`;
    }

    if (props.group.length > 0) {
      let groupQuery = "";
      props.group.map((data) => {
        groupQuery += `&group[]=${data}`;
      });
      requestURL = requestURL + `${groupQuery}`;
    }

    if (props.tags.length > 0) {
      let tagsQuery = "";
      props.tags.map((data) => {
        tagsQuery += `&tags[]=${data}`;
      });
      requestURL = requestURL + `${tagsQuery}`;
    }

    if (props.category.length > 0) {
      let categoryQuery = "";
      props.category.map((data) => {
        categoryQuery += `&category[]=${data}`;
      });
      requestURL = requestURL + `${categoryQuery}`;
    }

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          if (response.data.length > 0) {
            if (page == 1) {
              setActivityPost(response.data);
              if (response.data.length < 7) {
                setHideLoadMore(true);
              } else {
                setHideLoadMore(false);
              }

              // for refresh or reset filter
              if (props.showListPost) {
                props.rightPanelHandler(response.data[0].id);
              }

              if (firstPostId == null) {
                setFirstPostId(response.data[0].id);
              }
              // first post display at right panel
            } else {
              response.data.map((forums) => {
                setActivityPost((activityPost) => [...activityPost, forums]);
              });
            }
          } else {
            setActivityPost([]);
            setEmptyMessage(t("Sorry! No more posts are available"));
            props.rightPanelHandler(null);
          }
        } else {
          setActivityPost([]);
          setEmptyMessage(t("Sorry! No more posts are available"));
          props.rightPanelHandler(null);
        }
        setIsMoreLoading(false);
        setLoadTopics(false);
      } else {
        setActivityPost([]);
        props.rightPanelHandler(null);
        setEmptyMessage(t("Sorry! No more posts are available"));
        setIsMoreLoading(false);
      }
    } catch (error) {}
    scrollToTop();
  };

  /*--- function for get more post details by increasing page number ---*/
  const loadMoreHandler = () => {
    if (emptyMessage == "") {
      setPage(page + 1);
      setIsMoreLoading(true);
    }
  };

  /*--- function for scroll to top when page reloads ---*/
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*--- function for send post id to main activity component ---*/
  const rightPanelHandler = (item) => {
    props.rightPanelHandler(item.id);
    document.body.classList.add("activity_open");
  };

  //function for delete forum
  const deleteForum = async (item, index) => {
    if (deleteIndex == null) {
      setDeleteIndex(index);
    } else {
      setDeleteIndex(null);
    }
    setIsDeleting(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_REMOVE_FORUM +
      `?token=${token}` +
      `&fid=${item.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        props.refreshActivity();
        setIsDeleting(false);
      }
    } catch (error) {}
  };

  //function for after modal close
  const afterModalClose = () => {
    setLoadTopics(true);
    allForums();
  };

  // list post runs by search query
  useEffect(() => {
    if (props.showListPost) {
      if (emptyMessage == t("Sorry! No more posts are available")) {
        setEmptyMessage("");
      }
      winnerIdState = undefined;
      setLoadTopics(true);
      setPage(1);
      allForums();
      props.hideListPost();
    }
  }, [props.showListPost]);

  // list post runs by page number
  useEffect(() => {
    if (isMoreLoading) {
      setLoadTopics(false);
    } else {
      setLoadTopics(true);
    }
    allForums();
  }, [page]);

  // useEffect for set default post for right panel
  useEffect(() => {
    if (firstPostId != null) {
      props.rightPanelHandler(firstPostId);
    }
  }, [firstPostId]);

  return (
    <>
      {loadTopics ? (
        <div className="glow_area list_glowArea">
          <div className="plan p-3 border rounded-3 placeholder-glow">
            <span className="placeholder placeholder-sm col-12 rounded-3 mb-3"></span>
            <span className="placeholder placeholder-sm col-10 rounded-3 mb-3"></span>
            <div className="user_placeHoledr">
              <div className="default_usr">
                <img src={defaultUser} alt="default-user" />
              </div>
              <div className="default_usr_name">
                <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              </div>
            </div>
            <div className="data_btnOuter">
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
              <span className="placeholder placeholder-sm col-3 rounded-3"></span>
            </div>
            <span className="placeholder placeholder-sm col-2 rounded-3 mb-3"></span>
            <p className="data_btnOuter placeholder-glow">
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
              <span className="placeholder placeholder-sm col-2 rounded-3"></span>
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="activity_leftpnl">
            {activityPost.map((forum, index) => {
              return (
                <div className="activity_lftItem" key={index}>
                  {/* ----- forum title start ----- */}
                  <div className="activty_lftHdng">
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        rightPanelHandler(forum);
                      }}
                    >
                      {forum.title}
                    </h3>
                  </div>
                  {/* ----- forum title end ----- */}

                  {/* ----- forum owner,post date and remove button start ----- */}
                  <div className="activity_userOuter">
                    <div className="activity_usrPnl">
                      {/* ----- forum owner start ------ */}
                      <div className="user_activty">
                        <img
                          src={
                            forum.moderator.thumbnail
                              ? forum.moderator.thumbnail
                              : defaultUser
                          }
                          alt=""
                        />
                      </div>
                      <div className="activty_usrname">
                        <h4>{forum.moderator.name}</h4>
                      </div>
                    </div>
                    {/* ----- forum owner end ----- */}

                    {/* ----- forum date start ----- */}
                    <div className="activity_date">
                      <i className="material-icons-round">calendar_today</i>
                      <span>{forum.created}</span>
                    </div>
                    {/* ----- forum date end ----- */}

                    {/* ----- forum remove button start ----- */}
                    {forum.moderator.id == loggedInUserID ||
                    userInfo.isadmin ? (
                      <div
                        className="activity_date"
                        style={{
                          cursor:
                            isDeleting && deleteIndex == index
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => deleteForum(forum, index)}
                      >
                        <i className="material-icons-round">delete</i>
                        <span>{t("Remove")}</span>
                        {isDeleting && deleteIndex == index ? (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                            style={{ height: "14px", width: "14px" }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {/* ----- forum remove button end ----- */}

                    <div className="activity_date">
                      <Link
                        className="add_tag_text_button"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#forum_leftpanel_tag_mdl"
                        onClick={() => {
                          setPostId(forum.id);
                        }}
                      >
                        <i className="material-icons-round">label</i>
                        <em>{t("Tag")}</em>
                      </Link>
                    </div>
                  </div>
                  {/* ----- forum owner,post date and remove button end ----- */}
                  <div className="winers_likeOuter">
                    <div className="likecmnts_pnl">
                      <div className="form_share">
                        <p>
                          {forum.isglobal == "1" ? (
                            <i className="material-icons-outlined">public</i>
                          ) : (
                            <i className="material-icons-outlined">settings</i>
                          )}
                        </p>
                        <p>
                          <i className="material-icons-outlined">thumb_up</i>
                          <em>{t("Likes")}</em>
                          <span>{forum.likes}</span>
                        </p>
                        <p>
                          <i className="material-icons-outlined">chat</i>
                          <em>{t("Comment")}</em>
                          <span>{forum.comments.count}</span>
                        </p>
                      </div>
                    </div>
                    {forum.winner.length == 0 || forum.winner == null ? null : (
                      <div
                        className="winner_user"
                        tabIndex="0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={forum.winner.name}
                      >
                        <div className="activty_usrname">
                          <h5>{t("Winner")}</h5>
                        </div>

                        <div className="user_activty">
                          <img
                            src={
                              forum.winner.image
                                ? forum.winner.image
                                : defaultUser
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="activity_tagsouter">
                    {forum.tags.length == 0 ? null : (
                      <div className="activity_tagsRow">
                        <h4>{t("Tags")}</h4>
                        <div className="experts_skill activity_skill">
                          <ul>
                            {forum.tags.slice(0, 4).map((item2, index2) => {
                              return (
                                <li key={index2}>
                                  <Link to="#">{item2.label}</Link>
                                </li>
                              );
                            })}
                          </ul>

                          {forum.tags.length > 4 ? (
                            <div className="more_tags">
                              <Link to="#">
                                +{forum.tags.length - 4}
                                {t("more")}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {forum.categories.length == 0 ? null : (
                      <div className="activity_tagsRow">
                        <h4>{t("Categories")}</h4>
                        <div className="experts_skill activity_skill">
                          <ul>
                            {forum.categories
                              .slice(0, 4)
                              .map((item2, index2) => {
                                return (
                                  <li key={index2}>
                                    <Link to="#" key={index2}>
                                      {item2.label}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ul>
                          {forum.categories.length >= 4 ? (
                            <div className="more_tags">
                              <Link to="#">
                                +{forum.categories.length} {t("more")}
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {emptyMessage == "" ? (
            <div className="loadmoreBtn text-center">
              {hideLoadMore ? null : (
                <Link
                  to="#"
                  className="basic_btn"
                  role="button"
                  onClick={loadMoreHandler}
                >
                  {t("Load More")}
                  {isMoreLoading == true ? (
                    <div
                      className="mx-2 spinner-border spinner-border-sm text-white"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : null}
                </Link>
              )}
            </div>
          ) : (
            <div className="loadmoreBtn text-center">
              <Link to="#" className="basic_btn">
                {emptyMessage}
              </Link>
            </div>
          )}
        </>
      )}
      <AddTagModalLeftPanel afterModalClose={afterModalClose} postId={postId} />
    </>
  );
};

export default ActivityLeftpanel;
